<template>
  <div class="relative">
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        Data Transaksi Keuangan<br />
        <span class="text-sm font-medium"
          >Form pembuatan bukti transaksi keuangan atau nota</span
        >
      </h2>
      <div
        class="w-full sm:w-auto md:flex justify-center items-center align-center mt-4 sm:mt-0"
      >
        <router-link
          class="btn btn-primary"
          :to="{ name: 'rekap-nota-bernomor' }"
        >
          Rekap Tagihan Nota
        </router-link>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-2">
      <div class="col-span-7">
        <div class="intro-y box p-5 mt-5">
          <div class="grid grid-cols-12 gap-2">
            <div class="mb-2 col-span-3">
              <label class="form-label">Tanggal</label>
              <input
                v-model="model.Tanggal"
                type="date"
                placeholder="Tanggal"
                class="form-control mb-2"
              />
            </div>
            <div class="mb-2 col-span-3">
              <label class="form-label">No. Nota</label>
              <input
                v-model="model.no_nota"
                type="text"
                placeholder="No. Nota"
                class="form-control mb-2"
              />
            </div>
            <div class="mb-2 col-span-6">
              <label class="form-label">Kas</label>
              <TailSelect
                v-model="model.KODE"
                :options="{
                  search: true,
                  classNames: 'w-full',
                  deselect: false,
                }"
              >
                <option
                  v-for="item in listKas"
                  :key="item.id"
                  :value="item.kode"
                >
                  {{ item.nama_kas }} ({{ item.kode }})
                </option>
              </TailSelect>
            </div>

            <div class="mb-2 col-span-12">
              <CariMahasiswa
                ref="cariMahasiswa"
                :show-btn="false"
                @dataMahasiswa="fetchDataMahasiswa"
              >
              </CariMahasiswa>

              <p class="text-red-600 italic py-1">
                Tuliskan nama pihak pemberi lainnya jika NPM tidak ditemukan
              </p>

              <label class="form-label">Diterima Dari</label>
              <div class="grid grid-cols-12 gap-1">
                <div class="col-span-8">
                  <input
                    v-model="model.nama"
                    type="text"
                    placeholder="Nama"
                    class="form-control mb-2"
                  />
                </div>
                <div class="col-span-4">
                  <input
                    v-model="model.NPM"
                    type="text"
                    placeholder="NPM"
                    readonly
                    class="form-control mb-2"
                  />
                </div>
              </div>
            </div>

            <div class="mb-2 col-span-9">
              <label class="form-label">Keterangan</label>
              <input
                v-model="model.Keterangan"
                type="text"
                placeholder="Keterangan"
                class="form-control mb-2"
              />
            </div>

            <div class="mb-2 col-span-3">
              <label class="form-label">Nilai Transaksi</label>
              <input
                v-model="model.Nilai"
                type="number"
                class="form-control mb-2"
              />
              <span v-if="model.Nilai > 0" class="text-green-800 font-medium">
                {{ $h.IDR(model.Nilai) }}
              </span>
            </div>

            <div class="mb-2 col-span-12">
              <label class="form-label">Staff Keuangan</label>
              <select v-model="model.staff_keuangan" class="form-select">
                <option
                  v-for="(nama, index) in listStaffKeuangan"
                  :key="index"
                  :value="nama"
                >
                  {{ nama }}
                </option>
              </select>
            </div>
            <div class="mb-2 col-span-12">
              <button class="btn btn-success" @click="simpanNota">
                <SaveIcon class="w-4 mr-2" />Simpan Nota
              </button>
              <button
                class="btn btn-warning ml-2"
                :disabled="model.nomor == null"
                @click="cetakNota"
              >
                <PrinterIcon class="w-4 mr-2" />Cetak Nota
              </button>

              <button class="btn btn-danger ml-2" @click="resetNota">
                <RefreshCcwIcon class="w-4 mr-2" />Reset
              </button>
            </div>
          </div>
        </div>

        <Alert
          v-if="statusSimpan.status != null"
          id="alerts1"
          class="mt-3"
          :type="statusSimpan.status ? 'alert-success' : 'alert-danger'"
          :dismissable="true"
          >{{ statusSimpan.msg }}</Alert
        >
      </div>
      <div class="col-span-5">
        <TableNotaCetak
          ref="tableNotaCetak"
          class="mt-6"
          @detailNota="detailNota"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CariMahasiswa from "@/views/mahasiswa/CariMahasiswa";
import { HTTPKEU } from "../../services/http";
import TableNotaCetak from "./TableNotaCetak";
import Alert from "@/components/alerts/Alert";
export default {
  name: "Form",
  components: { TableNotaCetak, CariMahasiswa, Alert },
  data() {
    return {
      nominal: 0,
      rupiahNominal: "",
      messageValidator: {},
      listKas: [],
      listStaffKeuangan: ["Yuvita", "Katrina"],
      model: {
        Tanggal: new Date().toISOString().slice(0, 10),
        KODE: "15.003",
        Keterangan: "",
        Nilai: 0,
        no_nota: "",
        NPM: "",
        nama: "",
        Kampus: "UNIV",
        staff_keuangan: "Yuvita",
        nomor: null,
      },
      statusSimpan: {
        msg: null,
        status: null,
      },
    };
  },
  mounted() {
    this.getJenisBiayaList();
  },
  methods: {
    getJenisBiayaList() {
      HTTPKEU.get("list/kasmasuk").then((res) => {
        this.listKas = res.data;
      });
    },
    fetchDataMahasiswa(d) {
      this.model.nama = d?.nama;
      this.model.NPM = d?.npm;
      this.model.Kampus = d?.mhs?.sms?.prodi?.fakultas?.nama_alias;
    },
    detailNota(data) {
      this.model = data;
      this.model.Tanggal = new Date(data.Tanggal).toISOString().slice(0, 10);
      this.model.Nilai = parseInt(data.Nilai);
      this.$refs.cariMahasiswa.clearSelectedMahasiswa();
    },
    cetakNota() {
      let apiurl = process.env.VUE_APP_KEUANGAN;
      if (this.model.nomor) {
        window.open(apiurl + "cetaknotabernomor/" + this.model.nomor, "_blank");
      }
    },
    simpanNota() {
      let data = this.model;
      data.Nilai = parseInt(data.Nilai);
      if (data.nomor) {
        HTTPKEU.put("kas_transaksi/" + data.nomor, data)
          .then((res) => {
            if (res.data.code === 200) {
              this.statusSimpan.msg = "Data berhasil disimpan";
              this.statusSimpan.status = true;
            } else {
              this.statusSimpan.msg = "Data gagal disimpan";
              this.statusSimpan.status = false;
            }
          })
          .finally(() => {
            this.$refs.tableNotaCetak.getTransaksi();
          });
      } else {
        HTTPKEU.post("kas_transaksi", data)
          .then((res) => {
            if (res.data.code === 200) {
              this.statusSimpan.msg = "Data berhasil disimpan";
              this.statusSimpan.status = true;
            } else {
              this.statusSimpan.msg = "Data gagal disimpan";
              this.statusSimpan.status = false;
            }
            //this.resetNota();
          })
          .finally(() => {
            this.$refs.tableNotaCetak.getTransaksi();
          });
      }
    },
    resetNota() {
      this.model = {
        Tanggal: new Date().toISOString().slice(0, 10),
        KODE: "15.003",
        Keterangan: "",
        Nilai: 0,
        no_nota: "",
        NPM: "",
        nama: "",
        Kampus: "",
        staff_keuangan: "Yuvita",
        nomor: null,
      };
      this.$refs.cariMahasiswa.clearSelectedMahasiswa();
      //this.clearFieldCariMahasiswa = true;
      this.$refs.tableNotaCetak.resetIndexSelected();
    },
  },
};
</script>

<style scoped></style>
