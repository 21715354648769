<template>
  <div class="relative">
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        Nota Bernomor<br />
        <span class="text-sm font-medium"
          >Rekap Transaksi Keuangan Menggunakan Nota Bernomor</span
        >
      </h2>
      <div
        class="w-full sm:w-auto md:flex justify-center items-center align-center mt-4 sm:mt-0"
      >
        <router-link class="btn btn-primary" :to="{ name: 'nota-bernomor' }">
          Buat Nota
        </router-link>
      </div>
    </div>
    <div class="intro-y box p-5 mt-5">
      <AlertSuccess v-if="alertSuccess" :message="messageAlert" />
      <AlertFailed v-if="alertFailed" :message="messageAlert" />

      <div class="md:flex mb-4 gap-2">
        <!--        <select v-model="pilSemester" class="form-select mb-2">
          <option
            v-for="semester in listSemester"
            :key="semester.id_smt"
            :value="semester.id_smt"
          >
            {{ semester.nm_smt }}
          </option>
        </select>-->
        <input v-model="piltgl1" type="date" class="form-control mb-2" />
        <input v-model="piltgl2" type="date" class="form-control mb-2" />
        <select v-model="pillogic" class="form-select w-auto mb-2">
          <option v-for="logic in listLogic" :key="logic" :value="logic">
            {{ logic }}
          </option>
        </select>
        <div v-if="isfilterkas">
          <TailSelect
            v-model="pilkas"
            :options="{
              search: true,
              classNames: 'w-full',
              deselect: false,
            }"
          >
            <option v-for="item in listKas" :key="item.id" :value="item.kode">
              {{ item.nama_kas }} ({{ item.kode }})
            </option>
          </TailSelect>
        </div>

        <button class="btn btn-success mb-2" @click="getDataHitungNota()">
          <SearchIcon></SearchIcon> Tampilkan
        </button>

        <button
          class="btn btn-outline-secondary rounded mb-2"
          @click="generateExcel()"
        >
          <DownloadIcon class="mr-1"></DownloadIcon> Excel
        </button>
        <button
          class="btn btn-outline-primary rounded mb-2"
          @click="cetakNota()"
        >
          <PrinterIcon class="mr-1"></PrinterIcon> Cetak
        </button>
      </div>

      <table class="table">
        <thead>
          <tr class="bg-gray-600 text-white text-center">
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              Nota
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              Tanggal
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              NPM
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              Nama
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              Jumlah
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              Keterangan
            </th>
            <th
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              Kode Kas
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in listhitungan"
            v-if="listhitungan.length > 0"
            :key="index"
            class="hover:bg-gray-300 odd:bg-gray-200"
          >
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              {{ item.no_nota }}
            </td>
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              {{ item.Tanggal }}
            </td>
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              {{ item.NPM }}
            </td>
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              {{ item.nama }}
            </td>

            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              {{ item.Nilai }}
            </td>
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              {{ item.Keterangan }}
            </td>
            <td
              class="border border-b-2 dark:border-indigo-600 whitespace-nowrap text-xs w-40"
            >
              {{ getKetKas(item.KODE) }} ({{ item.KODE }})
            </td>
          </tr>
          <tr
            v-if="listhitungan.length > 0"
            class="hover:bg-gray-300 odd:bg-gray-200"
          >
            <td colspan="4" class="border">Total</td>
            <td class="border">{{ rupiahLocal(totalTransaksi) }}</td>
            <td class="border"></td>
            <td class="border"></td>
          </tr>
          <tr v-else class="text-center">
            <td colspan="7" class="border">Belum ada Data</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import AlertSuccess from "@/components/alerts/AlertSuccess.vue";
import AlertFailed from "@/components/alerts/AlertFailed.vue";
import moment from "moment";
import writeXlsxFile from "write-excel-file";
import { saveAs } from "file-saver";
import { HTTP } from "@/services/http";
import { keuangan } from "@/services/models";
import { HTTPKEU } from "../../services/http";
export default {
  name: "Main",
  components: {
    AlertSuccess,
    AlertFailed,
  },
  data() {
    return {
      isfilterkas: true,
      pilSemester: 0,
      piltgl1: moment().format("YYYY-MM-DD"),
      piltgl2: moment().format("YYYY-MM-DD"),
      pilkas: "15.003",
      pillogic: "Dan",
      totalTransaksi: 0,
      listhitungan: [],
      listSemester: [],
      listHonor: [],
      idData: [],
      listBulan: [],
      bulan: "",
      pilBulan: "",
      alertSuccess: false,
      alertFailed: false,
      messageAlert: "",
      listKas: [],
      listLogic: ["Dan", "Hanya", "Tanpa Filter Kas"],
    };
  },
  watch: {
    pilSemester: {
      handler: function () {
        //this.getListHonorarium();
      },
      immediate: true,
    },
    pillogic: {
      handler: function (h) {
        console.log(h);
        this.isfilterkas = h !== "Tanpa Filter Kas";
        console.log(this.isfilterkas);
        //this.getListHonorarium();
      },
      immediate: true,
    },
  },
  mounted() {
    this.getActiveSemester();
    this.getJenisBiayaList();
    // this.getDataHitungNgajar();
  },
  methods: {
    getKetKas(kode) {
      let ket = this.listKas.filter((prm) => prm.kode === kode);
      return ket[0].nama_kas;
    },
    getJenisBiayaList() {
      HTTPKEU.get("list/kasmasuk").then((res) => {
        this.listKas = res.data;
      });
    },
    getActiveSemester() {
      HTTP.get("is_active/semester_keu").then((res) => {
        let act = res.data.data.filter((prm) => prm.a_periode_aktif === 1);
        this.pilSemester = act[0].id_smt;
        this.listSemester = res.data.data;
      });
    },
    getDataHitungNota() {
      this.alertSuccess = false;
      this.alertFailed = false;

      let prm = {
        //id_smt: this.pilSemester,
        tgl1: this.piltgl1,
        tgl2: this.piltgl2,
        kode: this.pilkas,
        filter: this.pillogic,
      };
      this.listhitungan = [];
      this.totalTransaksi = 0;
      keuangan.KasTransaksi.postCustomPath("rekap", prm).then((res) => {
        res.data.forEach((item) => {
          this.totalTransaksi =
            parseFloat(item.Nilai) + parseFloat(this.totalTransaksi);
          this.listhitungan.push(item);
        });

        this.getBulan();
      });
    },
    getBulan() {
      let listBulan = [
        { id: "01", name: "Januari" },
        { id: "02", name: "Februari" },
        { id: "03", name: "Maret" },
        { id: "04", name: "April" },
        { id: "05", name: "Mei" },
        { id: "06", name: "Juni" },
        { id: "07", name: "Juli" },
        { id: "08", name: "Agustus" },
        { id: "09", name: "September" },
        { id: "10", name: "Oktober" },
        { id: "11", name: "November" },
        { id: "12", name: "Desember" },
      ];

      let tes = listBulan.filter((res) => {
        if (
          res.id === moment(this.piltgl1).format("MM") ||
          res.id === moment(this.piltgl2).format("MM")
        ) {
          return res;
        }
      });

      let bln = listBulan.find((res) => {
        if (res.id === moment(this.piltgl1).format("MM")) {
          return res;
        }
      });
      this.bulan = bln?.name;

      this.listBulan = tes;
    },
    async generateExcel() {
      const rows = [
        [
          {
            value: "No",
            fontWeight: "bold",
          },
          {
            value: "Nota",
            fontWeight: "bold",
          },
          {
            value: "Tanggal",
            fontWeight: "bold",
          },
          {
            value: "Kode",
            fontWeight: "bold",
          },
          {
            value: "Kas",
            fontWeight: "bold",
          },
          {
            value: "NPM",
            fontWeight: "bold",
          },
          {
            value: "Nama",
            fontWeight: "bold",
          },
          {
            value: "Jumlah",
            fontWeight: "bold",
          },
          {
            value: "Keterangan",
            fontWeight: "bold",
          },
          {
            value: "Kampus",
            fontWeight: "bold",
          },
          {
            value: "Staff Keuangan",
            fontWeight: "bold",
          },
        ],
      ];

      if (this.listhitungan.length > 0) {
        let n = 1;
        this.listhitungan.forEach((item) => {
          rows.push([
            {
              type: Number,
              value: n,
            },
            {
              type: String,
              value: item.no_nota,
            },
            {
              type: String,
              value: item.Tanggal,
            },
            {
              type: String,
              value: item.KODE,
            },
            {
              type: String,
              value: this.getKetKas(item.KODE),
            },
            {
              type: String,
              value: item.NPM,
            },
            {
              type: String,
              value: item.nama,
            },
            {
              type: Number,
              value: parseInt(item?.Nilai),
            },
            {
              type: String,
              value: item.Keterangan,
            },
            {
              type: String,
              value: item.Kampus,
            },
            {
              type: String,
              value: item.staff_keuangan,
            },
          ]);
          n++;
        });
        this.getBulan();
        let blobDataJadwal = await writeXlsxFile(rows, {
          filePatch: `Data Transaksi Nota Bulan ${this.bulan}.xlsx`,
        });
        const blobData = new Blob([blobDataJadwal], {
          type: "application/vnd.ms-excel;charset=utf-8",
        });

        saveAs(blobData, `Data Transaksi Nota Bulan ${this.bulan}.xlsx`);
        this.exportExcelString = "Export Excel";
      }
    },
    rupiahLocal(nominal) {
      let rupiahLocal = Intl.NumberFormat("id-ID");
      if (nominal != null) {
        return "Rp " + rupiahLocal.format(nominal) + ",00";
      } else {
        return "0";
      }
    },
    cetakNota() {
      let apiurl = process.env.VUE_APP_KEUANGAN;
      if (this.piltgl1 != "") {
        window.open(
          apiurl +
            "cetakrekapnotabernomor?tgl1=" +
            this.piltgl1 +
            "&tgl2=" +
            this.piltgl2 +
            "&kode=" +
            this.pilkas +
            "&filter=" +
            this.pillogic,
          "_blank"
        );
      }
    },
  },
};
</script>

<style scoped></style>
