<template>
  <div>
    <div class="grid grid-cols-12 gap-2">
      <div class="mb-2 col-span-3">
        <label class="form-label">Filter By</label>
        <select v-model="fby" class="form-select">
          <option
            v-for="(f, index) in filterbylist"
            :key="index"
            :value="f.value"
          >
            {{ f.title }}
          </option>
        </select>
      </div>
      <div class="mb-2 col-span-5">
        <label class="form-label">&nbsp;</label>
        <input
          v-model="fvalue"
          type="text"
          placeholder="Filter"
          class="form-control mb-2"
        />
      </div>
      <div class="mb-2 col-span-3">
        <div class="flex mt-7">
          <div class="flex-1">
            <button class="btn btn-sm btn-success" @click="filterData">
              <SaveIcon class="w-4 mr-2" />Filter
            </button>
          </div>
          <div class="flex-1">
            <button class="btn btn-sm btn-warning ml-2" @click="getTransaksi">
              <SaveIcon class="w-4 mr-2" />Reset
            </button>
          </div>
        </div>
      </div>
    </div>

    <table class="table">
      <thead>
        <tr class="bg-gray-700 dark:bg-dark-2 text-white">
          <th class="whitespace-nowrap">No. Nota</th>
          <th class="whitespace-nowrap">Kas</th>
          <th class="whitespace-nowrap">Diterima Dari</th>
          <th class="whitespace-nowrap">Nominal</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(nota, key) in listTransaksi"
          :key="nota.nomor"
          class="hover:bg-gray-200 dark:hover:bg-dark-7 cursor-pointer"
          :class="{
            'bg-gray-300 dark:bg-dark-7 dark:hover:bg-dark-7':
              key === indexSelected,
          }"
          @click="detailNota(key, nota.nomor)"
        >
          <td class="border-b dark:border-dark-5">
            {{ nota.no_nota }}
          </td>
          <td class="border-b dark:border-dark-5">
            {{ nota.KODE }}<br />
            {{ nota.Keterangan }}
          </td>
          <td class="border-b dark:border-dark-5">
            {{ nota.NPM }}
            {{ nota.nama }}
          </td>
          <td class="border-b dark:border-dark-5">
            {{ $h.IDR(nota.Nilai) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { HTTPKEU } from "../../services/http";

export default {
  name: "TableNotaCetak",
  data() {
    return {
      listTransaksi: [],
      notaTransaksi: {},
      indexSelected: null,
      fby: "npm",
      fvalue: "",
      filterbylist: [
        { title: "NPM", value: "npm" },
        { title: "Nama Pemberi", value: "nama" },
        { title: "No. Nota", value: "no_nota" },
        { title: "Keterangan", value: "Keterangan" },
      ],
    };
  },
  mounted() {
    this.getTransaksi();
  },
  methods: {
    getTransaksi() {
      this.fvalue = "";
      HTTPKEU.get("kas_transaksi").then((res) => {
        this.listTransaksi = res.data?.data;
      });
    },
    detailNota(idx, nomor) {
      this.indexSelected = idx;
      this.listTransaksi.filter((item) => {
        if (item.nomor === nomor) {
          this.notaTransaksi = item;
          this.$emit("detailNota", this.notaTransaksi);
        }
      });
    },
    resetIndexSelected() {
      this.indexSelected = null;
    },
    filterData() {
      HTTPKEU.get("kas_transaksi", {
        params: {
          filterby: this.fby,
          filtervalue: this.fvalue,
        },
      }).then((res) => {
        this.listTransaksi = res.data?.data;
      });
    },
  },
};
</script>

<style scoped></style>
