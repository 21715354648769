<template>
  <MainLayout>
    <div class="relative">
      <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
        <h2 class="text-lg font-medium mr-auto">Daftar Permohonan Akademik</h2>
        <div
          class="w-full sm:w-auto md:flex justify-center items-center align-center mt-4 sm:mt-0"
        >
          <router-link
            class="btn btn-primary"
            :to="{ name: 'tagihan-validasi' }"
          >
            Validasi Transaksi
          </router-link>
        </div>
      </div>

      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 lg:col-span-12">
          <div v-if="loading" class="block-loading">
            <LoadingIcon icon="three-dots" class="w-8 h-8" />
            <div class="text-center text-xs mt-2">
              Loading data... Please Wait...
            </div>
          </div>

          <div class="intro-y box">
            <CariMahasiswa class="p-5" @dataMahasiswa="fetchDataMahasiswa">
              <template #button>
                <PlusCircleIcon class="h-4" /> Buat Tagihan
              </template>
            </CariMahasiswa>
            <InfoMahasiswa
              v-if="dataPD?.nm_pd"
              class="pl-5"
              :pesertadidik="dataPD"
              :dosen-p-a="dosenPA"
            />
          </div>

          <div class="intro-y box p-5 mt-5">
            <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
              <form
                id="tabulator-html-filter-form"
                class="xl:flex sm:mr-auto"
                @submit.prevent="onFilter"
              >
                <div class="sm:flex items-center sm:mr-4">
                  <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                    >Field</label
                  >
                  <select
                    id="tabulator-html-filter-field"
                    v-model="filter.field"
                    class="form-select w-full sm:w-32 xxl:w-full mt-2 sm:mt-0 sm:w-auto"
                  >
                    <option value="reg_pd.nipd">NPM</option>
                    <option value="peserta_didik.nm_pd">Nama</option>
                    <option value="id_smt">Semester</option>
                  </select>
                </div>

                <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                  <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
                    >Value</label
                  >
                  <select
                    v-if="filter.field == 'id_smt'"
                    v-model="filter.value"
                    class="form-select"
                  >
                    <option
                      v-for="(item, index) in listOptionsState?.semester"
                      :key="index"
                      :value="item.id"
                    >
                      {{ item.text }}
                    </option>
                  </select>
                  <input
                    v-else
                    id="tabulator-html-filter-value"
                    v-model="filter.value"
                    type="text"
                    class="form-control sm:w-40 xxl:w-full mt-2 sm:mt-0"
                    placeholder="Search..."
                  />
                </div>
                <div class="mt-2 xl:mt-0">
                  <button
                    id="tabulator-html-filter-go"
                    type="button"
                    class="btn btn-primary w-full sm:w-16"
                    @click="onFilter"
                  >
                    Go
                  </button>
                  <button
                    id="tabulator-html-filter-reset"
                    type="button"
                    class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
                    @click="onResetFilter"
                  >
                    Reset
                  </button>
                </div>

                <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
                  <div class="form-radio ml-10">
                    <input
                      id="all"
                      v-model="showing"
                      type="radio"
                      value="0"
                      class="form-check-input w-auto"
                      @click="resetFilter"
                    />
                    <label class="form-check-label w-full" for="all"
                      >Tampil Semua
                    </label>
                  </div>
                  <div class="form-radio ml-3">
                    <input
                      id="_baa"
                      v-model="showing"
                      type="radio"
                      value="2"
                      class="form-check-input w-auto"
                      @click="resetFilter"
                    />
                    <label class="form-check-label w-full" for="_baa"
                      >Diterima BAA
                    </label>
                  </div>

                  <div class="form-radio ml-3">
                    <input
                      id="_bak"
                      v-model="showing"
                      type="radio"
                      value="3"
                      class="form-check-input w-auto"
                      @click="resetFilter"
                    />
                    <label class="form-check-label w-full" for="_bak"
                      >Telah Divalidasi
                    </label>
                  </div>
                </div>
              </form>
              <div class="flex mt-5 sm:mt-0">
                <button
                  id="tabulator-print"
                  class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
                  @click="onPrint"
                >
                  <PrinterIcon class="w-4 h-4 mr-2" /> Print
                </button>
                <div class="dropdown w-1/2 sm:w-auto">
                  <button
                    class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
                    aria-expanded="false"
                  >
                    <FileTextIcon class="w-4 h-4 mr-2" /> Export
                    <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
                  </button>
                  <div class="dropdown-menu w-40">
                    <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                      <a
                        id="tabulator-export-xlsx"
                        href="javascript:"
                        class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                        @click="onExportXlsx"
                      >
                        <FileTextIcon class="w-4 h-4 mr-2" /> Export XLSX
                      </a>
                      <a
                        id="tabulator-export-html"
                        href="javascript:"
                        class="flex items-center block p-2 transition duration-300 ease-in-out bg-white dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md"
                        @click="onExportHtml"
                      >
                        <FileTextIcon class="w-4 h-4 mr-2" /> Export HTML
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="overflow-x-auto scrollbar-hidden">
              <div
                id="tabulator"
                ref="tableRef"
                class="mt-5 table-report table-report--tabulator"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <ModalDialogPositive
        :id="'validasi-modal'"
        :question="
          'Anda yakin ingin memvalidasi pembayaran tagihan ' +
          detail.tagihan?.NamaProduk +
          ' ' +
          detail.tagihan?.Nama +
          '?'
        "
      >
        <template #delete-button>
          <button
            type="button"
            class="btn btn-success w-24"
            @click="konfirmValidasi"
          >
            Ya
          </button>
        </template>
      </ModalDialogPositive>
    </div>

    <SlideOver :open="panel.panelOpen" size="max-w-5xl" @close="closePanel">
      <template #title>Detail Tagihan dan Transaksi Pembayaran</template>
      <div class="h-full" aria-hidden="true">
        <Alert
          v-if="statusValidasi.status != null"
          :type="statusValidasi.status ? 'alert-success' : 'alert-danger'"
          :dismissable="true"
          >{{ statusValidasi.msg }}</Alert
        >
        <div
          v-if="confirmValidasi"
          class="p-3 text-center border border-yellow-500 mb-3 font-medium text-lg text-yellow-700"
        >
          Anda yakin ingin memvalidasi pembayaran tagihan
          <b>{{ detail.tagihan?.NamaProduk }}</b> mahasiswa
          <b>{{ detail.tagihan?.Nama }}</b
          >?
          <div class="content-center mt-2 text-xs">
            <select
              v-model="model.account_code"
              class="form-select form-select-sm w-auto mr-2 border-yellow-700"
            >
              <option value="720.100">Cetak Transkrip</option>
              <option value="720.101">Pindah Shift</option>
              <option value="720.102">Cuti Akademik</option>
            </select>
            <button
              type="button"
              class="btn btn-success w-24"
              @click="konfirmValidasiPermAkad"
            >
              Ya
            </button>

            <button
              type="button"
              class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 ml-2"
              @click="confirmValidasi = false"
            >
              Tidak
            </button>
          </div>
        </div>
        <DetailTransaksi
          :detail="detail.tagihan"
          :process="allowValidasi"
          @validasiTransaksi="validasiBak"
        ></DetailTransaksi>
      </div>
    </SlideOver>

    <SlideOver :open="panel.panelBuatOpen" size="max-w-4xl" @close="closePanel">
      <template #title>Buat Tagihan</template>
      <div class="h-full" aria-hidden="true">
        <FormBuatTagihanPermohonan
          :detail="detail"
          @buatTagihan="resBuatTagihan"
        ></FormBuatTagihanPermohonan>
      </div>
    </SlideOver>

    <SlideOver
      :open="buatTagihanMhs"
      size="max-w-5xl"
      @close="buatTagihanMhs = false"
    >
      <template #title>Buat Tagihan Keuangan Mahasiswa</template>
      <div class="h-full" aria-hidden="true">
        <FormBuatTagihan :detail="buatTagihanData"></FormBuatTagihan>
      </div>
    </SlideOver>

    <SlideOver
      :open="panel.panelOpenDetail"
      size="max-w-5xl"
      @close="closePanel"
    >
      <template #title>Detail Permohonan Akademik</template>
      <div class="h-full" aria-hidden="true">
        <Alert
          v-if="statusValidasi.status != null"
          :type="statusValidasi.status ? 'alert-success' : 'alert-danger'"
          :dismissable="true"
          >{{ statusValidasi.msg }}</Alert
        >
        <div
          v-if="confirmValidasi"
          class="p-3 text-center border border-yellow-500 mb-3 font-medium text-lg text-yellow-700"
        >
          Anda yakin ingin memvalidasi pembayaran permohonan akademik mahasiswa
          <b>{{ detail.tagihan?.nm_pd }}</b
          >?
          <div class="content-center mt-2 text-xs">
            <select
              v-model="model.account_code"
              class="form-select form-select-sm w-auto mr-2 border-yellow-700"
            >
              <option value="720.100">Cetak Transkrip</option>
              <option value="720.101">Pindah Shift</option>
              <option value="720.102">Cuti Akademik</option>
            </select>
            <button
              type="button"
              class="btn btn-success w-24"
              @click="konfirmValidasiPermAkad"
            >
              Ya
            </button>

            <button
              type="button"
              class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 ml-2"
              @click="confirmValidasi = false"
            >
              Tidak
            </button>
          </div>
        </div>
        <DetailPermohonan
          :detail="detail"
          @validasiBak="validasiBak"
        ></DetailPermohonan>
      </div>
    </SlideOver>
  </MainLayout>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import xlsx from "xlsx";
import { useStore } from "vuex";
import feather from "feather-icons";
import { HTTPKEU } from "@/services/http";
import { option } from "@/utils/listoptions";
import MainLayout from "@/layouts/main/MainLayout";
import SlideOver from "@/layouts/main/SlideOver";
import { tblTabulator } from "@/utils/tabulator";
import { helper } from "@/utils/helper";
import store from "@/store";
import ModalDialogPositive from "@/components/utility/ModalDialogPositive";
import CariMahasiswa from "@/views/mahasiswa/CariMahasiswa";
import InfoMahasiswa from "../../../components/mahasiswa/InfoMahasiswa";
import { mahasiswa } from "@/services/models";
import DetailTransaksi from "@/views/tagihan/validasi/DetailTransaksi";
import Alert from "../../../components/alerts/Alert";
import DetailPermohonan from "./DetailPermohonan";
import FormBuatTagihanPermohonan from "../../tagihan/FormBuatTagihanPermohonan";
import FormBuatTagihan from "../../tagihan/FormBuatTagihan";

export default {
  name: "Main",
  components: {
    FormBuatTagihanPermohonan,
    FormBuatTagihan,
    DetailPermohonan,
    Alert,
    DetailTransaksi,
    InfoMahasiswa,
    CariMahasiswa,
    ModalDialogPositive,
    SlideOver,
    MainLayout,
  },

  setup() {
    const tableRef = ref();
    const tabulator = ref();
    const idData = ref();
    const store = useStore();
    const filter = reactive({
      field: "reg_pd.nipd",
      type: "like",
      value: "",
    });
    const showing = ref(2);

    let model = reactive({
      kode_bank: "BCA",
      kode_produk: null,
      kode_sub_comp: null,
      id_smt: null,
      npm: null,
      add_info: "",
      tgl_bayar: null,
      account_code: "720.100",
      id_pengajuan: null,
    });
    let panelOpen = ref(false);
    let panelBuatOpen = ref(false);
    let panelOpenDetail = ref(false);
    let panel = reactive({ panelOpen, panelBuatOpen, panelOpenDetail });
    let tagihan = null;
    let detail = reactive({ tagihan });

    const showDetail = (e, cell) => {
      idData.value = cell.getData().id;
      detail.tagihan = cell.getData();
      panel.panelOpenDetail = true;
      model.id_pengajuan = cell.getData().id;
    };

    const detailBAK = (e, cell) => {
      idData.value = cell.getData().id;
      detail.tagihan = cell.getData();

      //validasi
      if (cell.getData().id_transaksi) {
        panel.panelOpen = true;
        model.id_smt =
          cell.getData().tagihan.TahunAkademik.slice(0, 4) +
          cell.getData().tagihan.KD_Semester;
        model.kode_produk = cell.getData().tagihan.KodeProduk;
        model.kode_bank = cell.getData().tagihan.KodeBank;
        model.kode_sub_comp = cell.getData().tagihan.SubComp;
        model.npm = cell.getData().tagihan.NPM;
        model.va = cell.getData().tagihan.NO_VA;
        model.no_upload = cell.getData().tagihan.NO_UPLOAD;
        model.add_info = "";
        model.tgl_bayar = null;
      }

      if (cell.getData().id_transaksi == null && cell.getData().status == 2) {
        panel.panelBuatOpen = true;
      }
      model.id_pengajuan = cell.getData().id;
    };

    //panel = true;
    const initTabulator = () => {
      let columns = [
        {
          formatter: "responsiveCollapse",
          width: 40,
          minWidth: 30,
          hozAlign: "center",
          resizable: false,
          headerSort: false,
        },
        { formatter: "rownum", title: "NO", hozAlign: "center", width: 40 },
        // For HTML table

        {
          title: "MAHASISWA",
          field: "Nama",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
          headerSort: true,
          formatter(cell) {
            return (
              "<div><div class='whitespace-normal font-medium'>" +
              cell.getData().nipd +
              "</div>" +
              "<div class='whitespace-normal text-xs'>" +
              cell.getData().nm_pd +
              "</div></div>"
            );
          },
        },
        {
          title: "SEMESTER",
          field: "id_smt",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
          headerSort: true,
          formatter(cell) {
            return cell.getData()?.smt.nm_smt;
          },
        },
        {
          title: "BIAYA",
          field: "biaya",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
          headerSort: true,
          formatter(cell) {
            return helper.IDR(cell.getData()?.biaya);
          },
        },
        {
          title: "PENGAJUAN",
          field: "jenis_pengajuan_id",
          hozAlign: "left",
          vertAlign: "top",
          print: true,
          download: true,
          headerSort: true,
          formatter(cell) {
            return cell.getData()?.jenis_pengajuan.nm_jenis_pengajuan;
          },
        },
        {
          title: "VALIDASI",
          print: true,
          download: true,
          hozAlign: "center",
          vertAlign: "top",
          columns: [
            {
              title: "KAPRODI",
              field: "tgl_acc_kaprodi",
              headerSort: false,
              width: 150,
              print: true,
              download: true,
              hozAlign: "center",
              formatter(cell) {
                let lblbelum = `<div class="py-1 px-2 rounded-full text-xs bg-purple-400 text-white text-center cursor-pointer font-medium">
              Belum
            </div>`;
                let lbltidak = `<div class="py-1 px-2 rounded-full text-xs bg-gray-100 text-center cursor-pointer font-medium">
              Tidak Ada
            </div>`;
                if (cell.getData().jenis_pengajuan_id == "3") {
                  return cell.getData().tgl_acc_kaprodi
                    ? '<div class="text-xs">' +
                        cell.getData().tgl_acc_kaprodi +
                        "</div>"
                    : lblbelum;
                } else {
                  return lbltidak;
                }
              },
            },
            {
              title: "BAA",
              field: "tgl_acc_baa",
              headerSort: false,
              width: 150,
              print: true,
              download: true,
              hozAlign: "center",
              formatter(cell) {
                let lblbelum = `<div class="py-1 px-2 rounded-full text-xs bg-purple-400 text-white text-center cursor-pointer font-medium">
              Belum
            </div>`;
                return cell.getData().tgl_acc_baa
                  ? '<div class="text-xs">' +
                      cell.getData().tgl_acc_baa +
                      "</div>"
                  : lblbelum;
              },
            },
            {
              title: "BAK",
              field: "tgl_acc_bak",
              headerSort: false,
              print: true,
              download: true,
              width: 150,
              hozAlign: "center",
              formatter(cell) {
                let lblbelum = `<div class="py-1 px-2 rounded-full text-xs bg-purple-400 text-white text-center cursor-pointer font-medium">
              Belum
            </div>`;
                if (
                  cell.getData().id_transaksi == null &&
                  cell.getData().tgl_acc_bak == null
                ) {
                  if (cell.getData().status == "2") {
                    return `<div class="py-1 px-2 rounded-full text-xs bg-green-800 text-white text-center cursor-pointer font-medium">
                            Buat Tagihan
                          </div>`;
                  } else {
                    return lblbelum;
                  }
                } else {
                  return cell.getData().tgl_acc_bak
                    ? '<div class="text-xs">' +
                        cell.getData().tgl_acc_bak +
                        "</div>"
                    : `<div class="py-1 px-2 rounded-full text-xs bg-green-800 text-white text-center cursor-pointer font-medium">
                            Validasi
                          </div>`;
                }
              },
              cellClick: detailBAK,
            },
          ],
        },

        {
          title: "",
          field: "id",
          hozAlign: "center",
          vertAlign: "middle",
          print: false,
          download: false,
          headerSort: false,
          formatter(cell) {
            let tagihan = cell.getData();
            if (tagihan?.bukti_bayar != null && tagihan?.status == "2") {
              const a = `<div class="flex lg:justify-center items-center">
                <btn class="flex btn btn-sm btn-primary items-center">
                  <i data-feather="check" class="w-3 h-3 mr-1"></i> Validasi
                </btn>
              </div>`;
              return `${a}`;
            } else {
              const a = `<div class="flex lg:justify-center items-center">
                <btn class="flex btn btn-sm btn-default items-center">
                  <i data-feather="eye" class="w-3 h-3 mr-1"></i> Detail
                </btn>
              </div>`;
              return `${a}`;
            }
          },
          cellClick: showDetail,
        },
      ];
      tabulator.value = tblTabulator.remoteDataTable(
        tableRef,
        "permohonanakademik/surat_pengajuan",
        columns,
        true,
        null,
        { show: showing.value }
      );
    };
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener("resize", () => {
        tabulator.value.redraw();
        feather.replace({
          "stroke-width": 1.5,
        });
      });
    };
    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value);
    };
    // On reset filter
    const onResetFilter = () => {
      filter.field = "reg_pd.nipd";
      filter.type = "like";
      filter.value = "";
      onFilter();
    };

    // Filter function
    const onFilterCustom = (field, type, value) => {
      tabulator.value.setFilter(field, type, value);
    };
    // Export
    const onExportCsv = () => {
      tabulator.value.download("csv", "data.csv");
    };
    const onExportJson = () => {
      tabulator.value.download("json", "data.json");
    };
    const onExportXlsx = () => {
      const win = window;
      win.XLSX = xlsx;
      tabulator.value.download("xlsx", "data.xlsx", {
        sheetName: "Products",
      });
    };
    const onExportHtml = () => {
      tabulator.value.download("html", "data.html", {
        style: true,
      });
    };
    // Print
    const onPrint = () => {
      tabulator.value.print();
    };
    onMounted(() => {
      initTabulator();
      reInitOnResizeWindow();
    });
    return {
      store,
      tabulator,
      tableRef,
      filter,
      onFilter,
      onFilterCustom,
      onResetFilter,
      onExportCsv,
      onExportJson,
      onExportXlsx,
      onExportHtml,
      onPrint,
      idData,
      panel,
      detail,
      model,
      showing,
    };
  },
  data() {
    return {
      smt: { a_periode_aktif: 0 },
      loading: false,
      angkatan: new Date().getFullYear(),
      smsSelected: null,
      smtSelected: null,
      subcomps: null,
      banks: null,
      produks: null,

      statusSimpan: {
        msg: null,
        status: null,
      },
      statusGetTagihan: {
        msg: null,
        status: null,
      },
      statusValidasi: {
        msg: null,
        status: null,
      },
      panelOpen: false,
      needreset: false,
      idRegPD: null,
      dataPD: null,
      dosenPA: null,
      transaksiBank: null,
      allowValidasi: false,
      confirmValidasi: false,
      buatTagihanMhs: false,
      buatTagihanData: { biaya: 10000 },
    };
  },

  computed: {
    semester() {
      let year = new Date().getFullYear();
      let years = [];
      for (let i = 2016; i <= year; i++) {
        years.push({ id: i, value: i });
      }
      return years;
    },
    listOptionsState() {
      return this.$store.state.main.listOptions;
    },
    tabulatorLoaded() {
      return this.$store.state.main.tabulatorDataLoaded;
    },
  },
  watch: {
    tabulatorLoaded: {
      handler: function (h) {
        if (h === true) {
          this.$nextTick(() => {
            store.commit("main/resetTabulatorDataLoaded");
          });
        }
      },
      immediate: true,
    },
    showing: {
      handler: function (h) {
        this.$nextTick(() => {
          this.tabulator.setData(
            process.env.VUE_APP_KEUANGAN + "permohonanakademik/surat_pengajuan",
            { show: this.showing },
            tblTabulator.ajaxConfig()
          );
        });
      },
    },
  },
  beforeMount() {
    option.fetchSemesterAkad();
    option.fetchProdi();
  },
  mounted() {
    //this.fetchDataMahasiswa();
  },

  methods: {
    fetchDataMahasiswa(d) {
      this.dosenPA = d?.map_mhs;
      this.idRegPD = d?.id_reg_pd;
      this.buatTagihanMhs = true;
      this.buatTagihanData.nm_pd = d?.nama;
      this.buatTagihanData.nipd = d?.npm;
      this.buatTagihanData.npm = d?.npm;
      this.buatTagihanData.id_reg_pd = d?.id_reg_pd;
      this.buatTagihanData.prodi = d?.mhs?.sms?.prodi;
      this.buatTagihanData.tahun = d?.mhs?.tahun;
      this.buatTagihanData.id_smt = 20221;
      /*mahasiswa.PesertaDidik.getData(id_pd).then((result) => {
        if (result?.status === 200) {
          this.dataPD = result.data.data;
          //console.log(this.dataPD);

        }
      });*/
    },
    validasiBak(tb, it) {
      this.confirmValidasi = true;
      this.allowValidasi = true;
      if (tb == null) {
        this.model.add_info = it?.infoTambahan != "x" ? it?.infoTambahan : "";
        this.model.tgl_bayar = it?.infoTambahan != "x" ? it?.tglBayar : null;
      }
      console.log(this.model);
    },
    konfirmValidasiPermAkad() {
      this.statusValidasi.status = null;
      this.statusValidasi.msg = null;

      HTTPKEU.post("permohonanakademik/validasi", { ...this.model })
        .then((res) => {
          if (res.status === 200) {
            this.statusValidasi.status = true;
            this.statusValidasi.msg = res.data.message;
          } else {
            this.statusValidasi.status = false;
            this.statusValidasi.msg = res.data.message;
          }
        })
        .catch((e) => {
          this.statusValidasi.status = false;
          this.statusValidasi.msg = e.response?.data?.message;
        })
        .finally(() => {
          this.resetFilter();
          this.confirmValidasi = false;
        });
    },
    closePanel(close) {
      this.panelOpen = close;
      this.panel.panelOpen = close;
      this.panel.panelBuatOpen = close;
      this.panel.panelOpenDetail = close;

      this.transaksiBank = null;
      this.allowValidasi = false;
      this.confirmValidasi = false;
      this.statusValidasi.status = null;
      this.statusValidasi.msg = null;
    },
    resetFilter() {
      this.tabulator.setData(
        process.env.VUE_APP_KEUANGAN + "permohonanakademik/surat_pengajuan",
        { show: this.showing },
        tblTabulator.ajaxConfig()
      );
    },
    resBuatTagihan(res) {
      if (res === true) {
        this.$nextTick(() => {
          this.tabulator.setData(
            process.env.VUE_APP_KEUANGAN + "permohonanakademik/surat_pengajuan",
            { show: this.showing },
            tblTabulator.ajaxConfig()
          );
        });
      }
    },
  },
};
</script>

<style scoped></style>
